import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kenfranco/Documents/dev/medgrocer/ep-mg-website/src/components/Layout/Layout.js";
import Container from "layout/Container";
import Collapsible from "elements/Collapsible";
import SEO from "layout/SEO";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container isCentered mdxType="Container">
      <SEO title="Informed Consent Form" mdxType="SEO" />
      <h2>{`Informed Consent Form`}</h2>
      <hr />
      <div className="content is-medium">
        <h3>{`Ethics and Confidentiality`}</h3>
        <p>{`All patient records and information are securely stored and can only be accessed by the Mental Health Team of MedGrocer composed of mental health professionals and coordinators for this service. Confidentiality is suspended under the following situations:`}</p>
        <ol>
          <li parentName="ol">{`When there is serious, known, foreseeable harm to yourself or others`}</li>
          <li parentName="ol">{`When instances of abuse, in any form, is currently being committed against a vulnerable person`}</li>
          <li parentName="ol">{`When a court order is issued or when such order is required by the law; and`}</li>
          <li parentName="ol">{`When the client or patient has authorized such release in writing`}</li>
        </ol>
        <h3>{`Risks of the Mental Health Service`}</h3>
        <p>{`The success of therapy or counselling is dependent on the quality of the efforts of both the mental health professional and the patient. The patient is responsible for the lifestyle choices or changes that they make as an outcome from therapy. The therapist is free from obligations to appear in private or legal proceedings on behalf of the patient unless a court order is issued or is required by law.`}</p>
        <h3>{`Duty to Warn`}</h3>
        <p>{`In the event that the mental health professional believes that the client is in danger, physically, emotionally, or psychologically, from themselves or another person, consent is given for the mental health professionals to warn the person in danger or contact any person in a position to prevent harm to the client/patient or another person, including law enforcement, medical personnel, or emergency contacts of the client/patient. This authorization expires upon termination of mental health services with the patient.`}</p>
        <h3>{`Termination of Services`}</h3>
        <p>{`At any point in time, the client/patient has the right to request in writing to terminate the service or consults to stop.`}</p>
        <h3>{`Documentation and Use of Professional Records`}</h3>
        <p>{`The mental health professional will take notes of what has occurred during the MH consultations, which will be used for monitoring and documenting client/patient progress.`}</p>
        <p>{`All data will be securely stored for the entire duration of the client’s sessions. All forms of data will be disposed and discarded in a secure manner that would be prevent processing after the client or patient terminated their mental health services or up to a maximum 15 years per the recommendation of the Department of Health as stated in Administrative Order 2016-0002 “Privacy Guidelines on the Implementation of the Philippine Health Information Exchange”.`}</p>
      </div>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      